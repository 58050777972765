import React, { useState, useEffect } from 'react';
import { useAuth } from '../authContext';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../pages/dashboard/4sphereIcon.png";
import DashIcon from "../pages/dashboard/Graph 1.svg";
import SignOutIcon from "../pages/dashboard/Sign Out Icon.svg";
import BenchCandidatesIcon from "./benchCandidatesIcon.svg";
import CollapseIcon from "../components/collapseIcon.svg";

const Dashnav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [navActive, setNavActive] = useState("active");
    const { logout, isLoggedIn, user } = useAuth();

    function goToRegister() {
        navigate("/dashboard");
    }

    function benchCandidatesNavigator() {
        navigate("/benchcandidates");
    }

    function ratesCandidatesNavigator() {
        navigate("/rateCandidates");
    }

    function myAssignedCandidatesNavigator() {
        navigate("/myAssignedCandidates");
    }

    function mySubmissionNavigator() {
        navigate("/mySubmission");
    }

    function handleLogout() {
        if (isLoggedIn) {
            logout();
            navigate("/");
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isLoggedIn) {
                navigate("/dashboard");
            }
        }, 100);
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);

    function toggleSidenav() {
        setNavActive(navActive === "active" ? "" : "active");
    }

    return (
        <div className={`dashnav ${navActive === "active" ? "active" : ""}`}>
            <div className="sidebar-collapse" onClick={toggleSidenav}>
                <img src={CollapseIcon} alt="icon" />
            </div>
            <div className="dash-inner col-flex">
                <div className="site-logo row-flex">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                <div className="dashnav col-flex">
                    {location.pathname !== "/404" && (
                        <div className={`dash-button row-flex dash ${location.pathname === "/dashboard" ? "active" : ""}`} onClick={goToRegister}>
                            <img src={DashIcon} alt="" className="icon 01" />
                            <span>Dashboard</span>
                        </div>
                    )}

                    {location.pathname !== "/404" && (
                        <div className={`dash-button row-flex ${location.pathname === "/benchcandidates" ? "active" : ""} ${user?.user_role === "recruiter" ? "hidden" : "show"}`} onClick={benchCandidatesNavigator}>
                            <img src={BenchCandidatesIcon} alt="" className="icon 01" />
                            <span>Bench Candidates</span>
                        </div>
                    )}

                    {location.pathname !== "/404" && (
                        <div className={`dash-button row-flex ${location.pathname === "/rateCandidates" ? "active" : ""} ${user?.user_role === "recruiter" ? "hidden" : "show"}`} onClick={ratesCandidatesNavigator}>
                            <img src={BenchCandidatesIcon} alt="" className="icon 01" />
                            <span>Rate Confirmations</span>
                        </div>
                    )}

                    <div className={`dash-button row-flex dash ${user?.user_role === "recruiter" ? "show" : "hidden"}`} onClick={myAssignedCandidatesNavigator}>
                        <img src={DashIcon} alt="" className="icon 01" />
                        <span>My Assigned Candidates</span>
                    </div>

                    <div className={`dash-button row-flex dash ${user?.user_role === "recruiter" ? "show" : "hidden"}`} onClick={mySubmissionNavigator}>
                        <img src={DashIcon} alt="" className="icon 01" />
                        <span>My submissions</span>
                    </div>

                    <div className="dash-button row-flex" onClick={handleLogout}>
                        <img src={SignOutIcon} alt="" className="icon 01" />
                        <span>Logout</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashnav;
