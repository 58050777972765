import React, { createContext, useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const login = (userData) => {
        setUser(userData);
        // console.log("session Id" + logSessionId);
        setIsLoggedIn(true);
        localStorage.setItem('user', JSON.stringify(userData));
    };
    const logout = () => {
        // Clear any session or user data
        setUser(null); // or your method of clearing user state
        localStorage.removeItem('authToken'); // if you're using localStorage to manage tokens
        setIsLoggedIn(false);
    };
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
            setIsLoggedIn(true);
        }

    }, []);


    return (
        <AuthContext.Provider value={{ isLoggedIn , login, user, logout}}>
            {children}
        </AuthContext.Provider>
    );
};





export const useAuth = () => {
    return useContext(AuthContext);
};
