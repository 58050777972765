import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LoginSignup from './pages/loginSignUp';
import { AuthProvider } from './authContext';
import Dashboard from './pages/dashboard/dashboard';
import Register from './pages/registerCandidate';
import BenchCandidates from './pages/benchcandidate/benchCandidates';
import RegisterCandidate from './pages/benchcandidate/registerCandidate';
import UserProfile from './pages/userProfile/userProfile';
import RateCandidates from './pages/rateConfirmation/rateConfirmation';
import MyAssignedCandidates from './pages/myAssignedCandidates/myAssignedCandidates';
import MySubmission from './pages/mysubmission/mySubmission';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path='/' element={<LoginSignup />}></Route>
            <Route path='/dashboard' element={<Dashboard />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route path='/benchcandidates' element={<BenchCandidates />}></Route>
            <Route path='/registerNewCandidates/' element={<RegisterCandidate />}></Route>
            <Route path='/userProfile' element={<UserProfile />}></Route>
            <Route path='/rateCandidates' element={<RateCandidates />}></Route>
            <Route path='/myAssignedCandidates' element={<MyAssignedCandidates />}></Route>
            <Route path='/mySubmission' element={<MySubmission />}></Route>
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
