import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import "./userstyle.css";
import "../benchcandidate/style.css";
import Dashnav from '../../components/dashnav';
import "../dashboard/style.css";
import ProfileDef from "../dashboard/profileIcon.svg";
import UserIcon from "./UserIcon.svg";

const UserProfile = () => {
    const navigate = useNavigate();
    const { isloggedin, user } = useAuth();
    
    // State for form inputs
    const [profile, setProfile] = useState({
        first_name: "",
        last_name:"",
        username: "",
        email: "",
        password: "",
        country: "",
        gender: "",
        mobile_no: "",
        country_code: ""
    });

    const [popupActive, setPopupActive] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const apiEndpoint = 'https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api';

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const profileFetchQuery = {
                    query: `SELECT id, first_name, last_name, username, email, password, country, gender, mobile_no, country_code FROM foursphere_recruiters.recruiterportal_users WHERE email='${user.email}'`
                };
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(profileFetchQuery)
                });

                if (!response.ok){
                    throw new Error('Failed to fetch profile data');
                }
                const data = await response.json();
                if(data.length > 0){
                    setProfile(data[0]);
                }
            } catch (error) {
                setError(error.message);
            }
        }
        if (user) {
            fetchProfileData();
        }
    }, [user]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!profile.first_name || 
            !profile.last_name || 
            !profile.email || 
            !profile.country || 
            !profile.gender || 
            !profile.mobile_no || 
            !profile.password || 
            !profile.country_code) {
            
            alert("Please fill out all fields.");
            return;
        }
        try {
            const updateProfileQuery = {
                query: `UPDATE foursphere_recruiters.recruiterportal_users SET first_name='${profile.first_name}', last_name='${profile.last_name}', username='${profile.username}', email='${profile.email}', password='${profile.password}', country='${profile.country}', gender='${profile.gender}', mobile_no='${profile.mobile_no}' WHERE email='${user.email}'`
            };
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateProfileQuery)
            });
            if (!response.ok) {
                throw new Error('Failed to update profile');
            }
            setPopupActive('Your profile has been updated successfully!<br><br>Thank you for taking the time to update your profile.');
        } catch (error) {
            setError(error.message);
        }
    };

    function closePopup() {
        setPopupActive("");
    }

    return (
        <div>
            <div className="main-dash row-flex">
                <Dashnav />
                <div className="content col-flex">
                    <div className="top-bar row-flex">
                        <div className="col-flex">
                            <div className="page-intro row-flex">
                                <img src={UserIcon} alt="page-icon" />
                                <h3 className="page-head">User Profile</h3>
                            </div>
                        </div>
                        <div className="col-flex">
                            <div className="profile-button row-flex">
                                <img src={ProfileDef} alt="" className="thumb" />
                                <div className="user-col col-flex">
                                    <h3 className="position">Your Profile</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="innerContent col-flex">
                        <div className="registerForm col-flex">
                            <div className="registerTopHead">
                                <h4>Create Your User Profile By filling all fields.</h4>
                            </div>
                            <div className="formInner col-flex">
                                <div className="inputRow row-flex">
                                    <div className="input col-flex">
                                        <span className="head">First Name</span>
                                        <input 
                                            type="text"
                                            name="first_name"
                                            className="login-input" 
                                            placeholder="Enter First Name" 
                                            value={profile.first_name} 
                                            onChange={handleInputChange} 
                                            readOnly
                                        />
                                    </div>
                                    <div className="input col-flex">
                                        <span className="head">Last Name</span>
                                        <input 
                                            type="text"
                                            name="last_name"
                                            className="login-input" 
                                            placeholder="Enter Last Name" 
                                            value={profile.last_name}
                                            onChange={handleInputChange} 
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="inputRow row-flex">
                                    <div className="input col-flex">
                                        <span className="head">Enter Your Gender</span>
                                        <select  
                                            type="text"
                                            name="gender"
                                            className="login-input" 
                                            placeholder="Enter Your Gender"
                                            value={profile.gender}
                                            onChange={handleInputChange}
                                            // disabled
                                        >
                                            <option value="">Select Your Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                    <div className="input col-flex">
                                        <span className="head">Primary Email ID</span>
                                        <input 
                                            type="text"
                                            name="email"
                                            className="login-input" 
                                            placeholder="Primary Email ID" 
                                            value={profile.email} 
                                            onChange={handleInputChange} 
                                            // readOnly
                                        />
                                    </div>
                                </div>
                                <div className="inputRow row-flex">
                                    <div className="row-flex" style={{width:'100%'}}>
                                        <div className="input col-flex" style={{width:'50%'}}>
                                            <span className="head">Country Code</span>
                                            <select 
                                                type="text"
                                                name="country_code"
                                                className="login-input" 
                                                placeholder="Enter country code"
                                                value={profile.country_code}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select Country Code</option>
                                                <option value="+91">IND +91</option>
                                                <option value="+01">USA +01</option>
                                            </select>
                                        </div>
                                        <div className="input col-flex">
                                            <span className="head">Mobile Number</span>
                                            <input 
                                                type="text" 
                                                name="mobile_no"
                                                className="login-input" 
                                                placeholder="Enter Mobile Number"
                                                value={profile.mobile_no} 
                                                onChange={handleInputChange} 
                                                pattern="\d*" // Ensures only numbers can be input
                                                maxLength="10" // Limits the input to 10 digits
                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Extra validation to ensure only numbers
                                            />
                                        </div>
                                    </div>
                                    <div className="input col-flex">
                                        <span className="head">Password</span>
                                        <input 
                                            type="password"
                                            name="password"
                                            className="login-input" 
                                            placeholder="Enter Your Password" 
                                            value={profile.password}
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                </div>
                                <div className="inputRow row-flex">
                                    <div className="input col-flex">
                                        <span className="head">Country</span>
                                        <input 
                                            type="text"
                                            name="country"
                                            className="login-input" 
                                            placeholder="Country"
                                            value={profile.country}
                                            onChange={handleInputChange} 
                                            // readOnly
                                        />
                                    </div>
                                </div>
    
                                <button className="registerCandidate" onClick={handleSubmit}>
                                    Submit >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {popupActive !=="" &&(
                <>
                    <div className="f-screen-popup">
                        <div className="popup-cont">
                            <div className="close" onClick={closePopup}>+</div>
                            <h3 dangerouslySetInnerHTML={{ __html: popupActive }}></h3>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default UserProfile;
