import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../authContext';
import Dashnav from '../../components/dashnav';
import "./style.css";
import "../rateConfirmation/rateStyles.css"
import ProfileDef from "./profileIcon.svg";
import PageIcon from "./DashIcon.svg";
import BenchIcon from "../benchcandidate/benchIcon.svg";

// Modal Component
const CandidateModal = ({ candidate, onClose }) => {
    if (!candidate) return null;
    return (
        <div className="modal-f-popup">
            <div className="modal-content col-flex">
                <h3>Candidate Details</h3>
                <div className="details row-flex">
                    <span className="object">Full Name:</span>
                    <span className="value">{candidate.candidate_full_name}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Assigned Recruiter:</span>
                    <span className="value">{candidate.from_email}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">vendor:</span>
                    <span className="value">{candidate.to_email}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Client Name:</span>
                    <span className="value">{candidate.client_name}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Rate:</span>
                    <span className="value">{candidate.rate}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Email:</span>
                    <span className="value">{candidate.email}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Contact:</span>
                    <span className="value">{candidate.contact}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Submission Status:</span>
                    <span className="value">{candidate.submission_status}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Submission Date:</span>
                    <span className="value">{new Date(candidate.submission_date).toLocaleDateString()}</span>
                </div>
                <div className="details row-flex">
                    <span className="object">Signature:</span>
                    <span className="value">{candidate.signature}</span>
                </div>
                <button className="close-modal" onClick={onClose}>+</button>
            </div>
        </div>
    );
};

const MySubmission = () => {
    const { isLoggedIn, login, user } = useAuth();
    const navigate = useNavigate();
    
    // State to store candidate details
    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [recruiterFilter, setRecruiterFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [dateFilter, setDateFilter] = useState("");
    const [dataDelete, setDataDelete] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [emailAttempted, setEmailAttempted] = useState(false); // Track if email has been attempted

   

    function goToAssigned() {
        navigate('/myAssignedCandidates');
    }
    useEffect(() => {
        // Check if user and user.email are available
        if (user && user.email) {
            const fetchCandidates = (email) => {
                fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        query: `SELECT from_email, to_email, candidate_full_name, client_name, rate, email, contact, signature, submission_status, submission_date, thread_id, id 
                                FROM foursphere_recruiters.candidate_rateconfirmations
                                WHERE from_email = '${email}'`
                    })
                })
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        setCandidates(data); // Set candidate data
                        setEmailAttempted(true); // Mark email as attempted
                    } else {
                        if (!emailAttempted) {
                            // Capitalize the first letter of email and resubmit
                            const modifiedEmail = user.email.charAt(0).toUpperCase() + user.email.slice(1);
                            fetchCandidates(modifiedEmail);
                            setEmailAttempted(true); // Mark email as attempted
                        }
                    }
                })
                .catch(error => console.error('Error fetching candidate details:', error));
            };

            fetchCandidates(user.email); // Fetch candidates initially
        }
    }, [user, user?.email, emailAttempted]); // Ensure user and user.email are correctly handled


    const ProfileOpen = () => {
        navigate("/userProfile");
    };

    
    useEffect(() => {
        let filtered = candidates;
    
        // Chain filters sequentially for performance
        // Search by name
        if (searchTerm) {
            filtered = filtered.filter(candidate =>
                candidate.candidate_full_name.toLowerCase().includes(searchTerm.toLowerCase()) 
            );
        }        
    
        // Search by recruiter mail
        if (recruiterFilter) {
            filtered = filtered.filter(candidate =>
                candidate.from_email.toLowerCase().includes(recruiterFilter.toLowerCase()) 
            );
        }        
    
    
        // Filter by submission status
        filtered = filtered.filter(candidate => {
            if (!statusFilter) return true;
            return candidate.submission_status === statusFilter;
        });
    
        // Function to convert a date to midnight in a specific time zone
            const convertToMidnightInTimeZone = (date, offset) => {
                // Convert the date to UTC
                const utcDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));

                // Adjust the time to the target time zone by applying the offset (in hours)
                const targetDate = new Date(utcDate.getTime() + (offset * 60 * 60 * 1000));

                // Return the target date normalized to midnight
                return new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate());
            };

            // Time zone offsets (in hours) relative to UTC
            const timeZoneOffsets = {
                PST: -8,    // Pacific Standard Time (UTC-8)
                MST: -7,    // Mountain Standard Time (UTC-7)
                CST: -6,    // Central Standard Time (UTC-6)
                EST: -5,    // Eastern Standard Time (UTC-5)
                IST: 5.5,   // Indian Standard Time (UTC+5:30)
            };

            // Define your time zone here (e.g., 'PST', 'IST', etc.)
            const selectedTimeZone = 'IST';

            // Filter by submission date using the selected time zone
            filtered = filtered.filter(candidate => {
                if (!dateFilter) return true;

                // Create Date objects from candidate.submission_date and dateFilter
                let candidateDate = new Date(candidate.submission_date);
                let filterDate = new Date(dateFilter);

                // Get the offset for the selected time zone
                const timeZoneOffset = timeZoneOffsets[selectedTimeZone];

                // Normalize both dates to midnight in the target time zone
                const candidateMidnight = convertToMidnightInTimeZone(candidateDate, timeZoneOffset);
                const filterMidnight = convertToMidnightInTimeZone(filterDate, timeZoneOffset);

                // Compare only the date part (no time involved)
                return candidateMidnight.getTime() === filterMidnight.getTime();
            });

         // Sort by submission date in ascending order
         filtered.sort((a, b) => new Date(b.submission_date) - new Date(a.submission_date));

        // Update the state with filtered candidates
        setFilteredCandidates(filtered);
    }, [searchTerm, recruiterFilter, statusFilter, dateFilter, candidates]);
    
    

    const openModal = (candidate) => {
        setSelectedCandidate(candidate);
    };

    const closeModal = () => {
        setSelectedCandidate(null);
    };

    const handleStatusChange = (event, candidate) => {
        const updatedStatus = event.target.value;
    
        // Update status in the database using candidate id
        fetch('https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `
                    UPDATE foursphere_recruiters.candidate_rateconfirmations
                    SET submission_status='${updatedStatus}'
                    WHERE id='${candidate.id}'` // Update by id instead of email
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log('Status updated:', data);
    
            // Update the local state to reflect the change based on id
            setFilteredCandidates(prevCandidates => 
                prevCandidates.map(c => 
                    c.id === candidate.id ? { ...c, submission_status: updatedStatus } : c
                )
            );
        })
        .catch(error => console.error('Error updating status:', error));
    };

    const handleDelete = async (candidateId) => {
        if (window.confirm('Are you sure you want to delete this candidate?')) {
            try {
                const response = await fetch(`https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        query: `DELETE FROM foursphere_recruiters.candidate_rateconfirmations WHERE id = ${candidateId}`
                    }),
                });
    
                if (response.ok) {
                    // Refresh the candidate list or update the state
                    alert('Candidate deleted successfully.');
                    setDataDelete(true); // Trigger a re-fetch of candidates to reflect the deleted candidate
                    // Example: Fetch candidates again
                } else {
                    throw new Error('Failed to delete candidate.');
                }
            } catch (error) {
                alert(error.message);
            }
        }
    };
    

    return (
        <div>
            <div className="main-dash row-flex">
                <Dashnav />
                <div className="content col-flex">
                    <div className="top-bar row-flex">
                        <div className="col-flex">
                            <div className="page-intro row-flex">
                                <img src={PageIcon} alt="page-icon" />
                                <h3 className="page-head green">My Submission</h3>
                            </div>
                        </div>
                        <div className="col-flex">
                            <div className="profile-button row-flex" onClick={ProfileOpen}>
                                <img src={ProfileDef} alt="" className="thumb" />
                                <div className="user-col col-flex">
                                    <h3 className="position">Your Profile</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-flex benchhead">
                        <div className="new-candidates row-flex" onClick={goToAssigned}>
                            {/* <img src={newCandidateIcon} alt="newCandidateIcon" /> */}
                            My Assigned Candidate
                        </div>
                        <div className="new-candidates row-flex">
                            No. Of Submissions :
                            <span>{filteredCandidates.length}</span>
                        </div>
                    </div>
                    <div className="filterBg col-flex">
                        <h4 className="head">
                            <i className="fas fa-filter" style={{ marginRight: '8px' }}></i>
                            Filters
                        </h4>
                        <div className="filters row-flex">
                            <select 
                                className="login-input" 
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)} 
                            >
                                <option value="">Search by candidate name</option>
                                {[...new Set(candidates.map(item => item.candidate_full_name))]
                                    .map((CandidateNames, index) => (
                                        <option key={index} value={CandidateNames}>{CandidateNames}</option>
                                    ))}
                            </select>
                            <select
                                className="login-input" 
                                value={recruiterFilter}
                                onChange={(e) => setRecruiterFilter(e.target.value)} 
                            >
                                <option value="">Filter By Recruiter</option>
                                {[...new Set(candidates.map(item => item.from_email))]
                                    .map((RecruiterList, index) => (
                                        <option key={index} value={RecruiterList}>{RecruiterList}</option>
                                    ))
                                }
                            </select>
                            <select 
                                className="login-input select-field" 
                                value={statusFilter} 
                                onChange={(e) => setStatusFilter(e.target.value)}
                            >
                                <option value="">Filter By submission</option>
                                                <option value="submitted to vendor">submitted to vendor</option>
                                                <option value="under review">under review</option>
                                                <option value="Shortlisted">Shortlisted</option>
                                                <option value="Not Shortlisted">Not Shortlisted</option>
                                                <option value="Technical Screening">Technical Screening</option>
                                                <option value="Interview Round 1">Interview Round 1</option>
                                                <option value="Interview Round 2">Interview Round 2</option>
                                                <option value="Interview Round 3">Interview Round 3</option>
                                                <option value="Interview Rejected">Interview Rejected</option>
                            </select>
                            <input 
                                type="date" 
                                className="login-input" 
                                value={dateFilter} 
                                onChange={(e) => setDateFilter(e.target.value)} 
                            />
                        </div>
                        <div className="innerContent col-flex">
                            <div className="candidates-table">
                                <div className="ov">
                                    <div className="table-head row-flex">
                                        <div className="top-head">
                                            <h4>Full Name</h4>
                                        </div>
                                        <div className="top-head">
                                            <h4>Client Name</h4>
                                        </div>
                                        <div className="top-head">
                                            <h4>Assigned Recruiter</h4>
                                        </div>
                                        <div className="top-head">
                                            <h4>Vendor</h4>
                                        </div>
                                        <div className="top-head">
                                            <h4>Rate</h4>
                                        </div>
                                        <div className="top-head">
                                            <h4>Submission Status</h4>
                                        </div>
                                        <div className="top-head">
                                            <h4>Submission Date</h4>
                                        </div>
                                        <div className="top-head">
                                            <h4>Actions</h4>
                                        </div>
                                    </div>
                                    {filteredCandidates.map(items => (
                                    <div className="table-data row-flex">
                                    <div className="items name-with-icon">
                                        <i className="fas fa-user"></i>
                                        {items.candidate_full_name}
                                    </div>
                                    <div className="items">
                                        {items.client_name}
                                    </div>
                                    <div className="items rate">
                                        {items.from_email.replace('$', '')}
                                    </div>
                                    <div className="items rate">
                                        {items.to_email.replace('$', '')}
                                    </div>
                                    <div className="items rate">
                                        <i className="fas fa-dollar-sign"></i>
                                        {items.rate.replace('$', '')}
                                    </div>
                                    <div className="items">
                                        <select
                                            className="login-input select-field"
                                            value={items.submission_status}
                                            onChange={(e) => handleStatusChange(e, items)}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="submitted to vendor">submitted to vendor</option>
                                            <option value="under review">under review</option>
                                            <option value="Shortlisted">Shortlisted</option>
                                            <option value="Not Shortlisted">Not Shortlisted</option>
                                            <option value="Technical Screening">Technical Screening</option>
                                            <option value="Interview Round 1">Interview Round 1</option>
                                            <option value="Interview Round 2">Interview Round 2</option>
                                            <option value="Interview Round 3">Interview Round 3</option>
                                            <option value="Interview Rejected">Interview Rejected</option>
                                        </select>
                                    </div>

                                    <div className="items">
                                        {new Date(items.submission_date).toLocaleDateString('en-US', {
                                        month: 'numeric',
                                        day: 'numeric',
                                        year: 'numeric',
                                        })}
                                    </div>
                                    <div className="items">
                                        <button onClick={() => openModal(items)}>
                                            <i className="fas fa-info-circle"></i>
                                        </button>
                                        <button onClick={() => handleDelete(items.id)} className="delete-btn">
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                    </div>
                                
                                    ))}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectedCandidate && (
                <CandidateModal candidate={selectedCandidate} onClose={closeModal} />
            )}
        </div>
    );
};

export default MySubmission;
